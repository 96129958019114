import React from 'react';
import { Quote } from 'lucide-react';

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Sarah Chen",
      role: "CTO, TechVision Inc",
      quote: "The AI models developed by the team exceeded our expectations. We've seen a 40% improvement in efficiency since implementation."
    },
    {
      name: "David Rodriguez",
      role: "Data Science Lead, Global Logistics",
      quote: "Their expertise in ML and supply chain optimization helped us reduce operational costs significantly. Outstanding results."
    },
    {
      name: "Emily Thompson",
      role: "VP Product, E-commerce Solutions",
      quote: "The customer analytics platform revolutionized how we understand user behavior. A game-changer for our business."
    }
  ];

  const Avatar = ({ className = "" }) => (
    <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="circuitGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#3B82F6" />
          <stop offset="100%" stopColor="#A855F7" />
        </linearGradient>
        <pattern id="circuitPattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <path d="M 0 10 L 20 10 M 10 0 L 10 20" stroke="rgba(255,255,255,0.2)" strokeWidth="1" fill="none"/>
        </pattern>
      </defs>
      <circle cx="50" cy="50" r="48" fill="url(#circuitGradient)" />
      <circle cx="50" cy="50" r="48" fill="url(#circuitPattern)" />
      <circle cx="50" cy="40" r="12" fill="white" fillOpacity="0.8" />
      <path d="M 50 55 Q 50 75 50 85 Q 35 85 25 75 Q 25 65 50 55" fill="white" fillOpacity="0.6" />
      <path d="M 50 55 Q 50 75 50 85 Q 65 85 75 75 Q 75 65 50 55" fill="white" fillOpacity="0.6" />
    </svg>
  );

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Client Testimonials
          </h2>
          <p className="text-gray-600 mt-2">Trusted by industry leaders worldwide</p>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {testimonials.map((item, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-6 hover:shadow-lg transition-all">
              <Quote className="h-8 w-8 text-blue-500 mb-4" />
              <p className="text-gray-700 mb-6">{item.quote}</p>
              <div className="flex items-center">
                <Avatar className="w-12 h-12 mr-4" />
                <div>
                  <h4 className="font-semibold text-gray-900">{item.name}</h4>
                  <p className="text-gray-500 text-sm">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl text-gray-700 max-w-2xl mx-auto">
            Join hundreds of forward-thinking companies transforming their business with AI
          </p>
          <button className="mt-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all">
            Get Started Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
