import React from 'react';
import { ArrowUpRight, ChevronRight, ArrowRight } from 'lucide-react';

const CaseStudiesSection = () => {
  const cases = [
    {
      category: "Generative AI",
      title: "Enterprise-Scale AI Content Platform",
      client: "Fortune 500 Tech Company",
      metrics: [
        { label: "Creation Speed", value: "85%", suffix: "Faster" },
        { label: "Output", value: "3x", suffix: "Increase" },
        { label: "Accuracy", value: "99.8%", suffix: "" }
      ],
      image: "/img/ai-platform.svg",
      color: "from-blue-500 to-blue-600"
    },
    {
      category: "Machine Learning",
      title: "Supply Chain Optimization",
      client: "International Logistics Leader",
      metrics: [
        { label: "Delays", value: "40%", suffix: "Less" },
        { label: "Costs", value: "25%", suffix: "Lower" },
        { label: "Savings", value: "$2.5M", suffix: "/yr" }
      ],
      image: "/img/supply-chain.svg",
      color: "from-purple-500 to-purple-600"
    },
    {
      category: "Data Science",
      title: "Customer Analytics Engine",
      client: "E-commerce Platform",
      metrics: [
        { label: "Conversion", value: "52%", suffix: "Up" },
        { label: "Retention", value: "38%", suffix: "Higher" },
        { label: "ROI", value: "2.8x", suffix: "" }
      ],
      image: "/img/analytics-engine.svg",
      color: "from-green-500 to-green-600"
    }
  ];

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-end mb-12">
          <div>
            <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-3">
              Client Success Stories
            </h2>
            <p className="text-gray-600">Transforming businesses through AI innovation</p>
          </div>
          <button className="group flex items-center space-x-2 bg-white px-4 py-2 rounded-lg shadow-sm hover:shadow transition-all">
            <span className="text-gray-900 font-medium">All cases</span>
            <ChevronRight className="h-4 w-4 text-blue-600 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>

        <div className="grid lg:grid-cols-3 gap-6">
          {cases.map((study, index) => (
            <div key={index} className="group bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300">
              <div className="relative">
                <div className={`bg-gradient-to-r ${study.color} text-white px-4 py-1 text-sm font-medium absolute top-3 left-3 rounded-full`}>
                  {study.category}
                </div>
                <img 
                  src={study.image} 
                  alt={study.title}
                  className="w-full h-40 object-cover group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              
              <div className="p-6">
                <p className="text-gray-500 text-sm mb-1">{study.client}</p>
                <h3 className="text-xl font-bold text-gray-900 mb-4 group-hover:text-blue-600 transition-colors">
                  {study.title}
                </h3>
                
                <div className="grid grid-cols-3 gap-3 mb-4">
                  {study.metrics.map((metric, idx) => (
                    <div key={idx} className="text-center">
                      <div className="text-lg font-bold text-blue-600">
                        {metric.value}
                      </div>
                      <div className="text-xs text-gray-500">
                        {metric.suffix}
                      </div>
                    </div>
                  ))}
                </div>

                <button className="flex items-center text-blue-600 font-medium group-hover:text-blue-700">
                  View case study 
                  <ArrowUpRight className="ml-2 h-4 w-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12">
          <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-xl p-8 flex items-center justify-between">
            <div className="text-white">
              <h3 className="text-2xl font-bold mb-1">Ready to Transform Your Business?</h3>
              <p className="text-blue-100 text-sm">Join our success stories</p>
            </div>
            <button className="bg-white text-blue-600 px-6 py-3 rounded-lg font-medium hover:shadow transition-all flex items-center group">
              Start Your Journey
              <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesSection;