import React, { useState, useEffect } from 'react';
import { Brain, Sparkles, Code, Bot, ArrowRight, CheckCircle, Zap, Database, LineChart, Search, 
         MessageSquare, Image, FileText, Lock, Globe, Share2, ArrowUpRight } from 'lucide-react';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';

const GenerativeAI = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const capabilities = [
    {
      icon: Bot,
      title: "Language Models",
      desc: "Custom GPT models tailored for your business needs",
      features: ["Multi-language support", "Domain-specific training", "Context awareness"]
    },
    {
      icon: Sparkles,
      title: "Content Generation",
      desc: "Automated content creation at enterprise scale",
      features: ["SEO optimization", "Brand voice consistency", "Multi-format content"]
    },
    {
      icon: Code,
      title: "Code Generation",
      desc: "AI-powered development assistance and automation",
      features: ["Multiple language support", "Code optimization", "Documentation generation"]
    },
    {
      icon: MessageSquare,
      title: "Conversational AI",
      desc: "Intelligent chatbots and virtual assistants",
      features: ["Natural language processing", "Context retention", "Multi-channel support"]
    },
    {
      icon: Image,
      title: "Image Generation",
      desc: "AI-powered visual content creation",
      features: ["Custom style adaptation", "Brand consistency", "High-resolution output"]
    },
    {
      icon: Database,
      title: "Data Synthesis",
      desc: "Generate realistic synthetic data for testing",
      features: ["Privacy compliant", "Scalable generation", "Custom data schemas"]
    },
    {
      icon: FileText,
      title: "Document Analysis",
      desc: "Intelligent document processing and generation",
      features: ["Multiple format support", "Data extraction", "Template generation"]
    },
    {
      icon: Lock,
      title: "Security & Privacy",
      desc: "Enterprise-grade security for AI operations",
      features: ["Data encryption", "Access control", "Audit trails"]
    },
    {
      icon: Globe,
      title: "Multilingual Support",
      desc: "Cross-language AI capabilities",
      features: ["100+ languages", "Cultural adaptation", "Local context awareness"]
    },
    {
      icon: Share2,
      title: "Integration & APIs",
      desc: "Seamless integration with existing systems",
      features: ["RESTful APIs", "Custom webhooks", "Real-time processing"]
    }
  ];

  const benefits = [
    { title: "Enhanced Efficiency", desc: "Reduce manual work by up to 70%" },
    { title: "Cost Reduction", desc: "Lower operational costs by 40-60%" },
    { title: "Improved Accuracy", desc: "Achieve 99.9% accuracy in tasks" },
    { title: "Faster Time-to-Market", desc: "Reduce development cycles by 50%" },
    { title: "Scalable Operations", desc: "Handle 10x workload without linear cost increase" }
  ];

  const industries = [
    {
      title: "E-commerce",
      uses: ["Product descriptions", "Customer support", "Personalization"]
    },
    {
      title: "Healthcare",
      uses: ["Medical documentation", "Patient interaction", "Research assistance"]
    },
    {
      title: "Finance",
      uses: ["Report generation", "Risk analysis", "Customer service"]
    },
    {
      title: "Manufacturing",
      uses: ["Quality control", "Process optimization", "Documentation"]
    }
  ];

  const processSteps = [
    { title: "Consultation", desc: "Understanding your specific needs" },
    { title: "Planning", desc: "Designing custom AI solution" },
    { title: "Development", desc: "Building and training models" },
    { title: "Integration", desc: "Seamless implementation" },
    { title: "Support", desc: "Ongoing optimization and maintenance" }
  ];

  return (
    <div>
    <Navbar/>
   
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-600 via-blue-700 to-purple-700 py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-full h-full bg-[linear-gradient(30deg,#1e3a8a_12%,transparent_30%)]" />
          <div className="absolute -left-1/4 top-1/4 w-96 h-96 bg-blue-400/20 rounded-full blur-3xl animate-pulse" />
        </div>
        
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl text-white">
            <div className="inline-flex items-center space-x-2 bg-white/10 rounded-full px-4 py-2 mb-6">
              <Brain className="h-5 w-5" />
              <span>Generative AI Solutions</span>
            </div>
            <h1 className="text-5xl font-bold mb-6 leading-tight">
              Transform Your Business with Next-Generation AI
            </h1>
            <p className="text-xl text-blue-100 mb-8 leading-relaxed">
              Harness the power of state-of-the-art generative AI models to automate,
              innovate, and scale your business operations.
            </p>
            <div className="flex space-x-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center">
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white/30 text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Capabilities Section */}
      <section className="py-20 bg-white" id="capabilities">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our AI Capabilities
            </h2>
            <p className="text-gray-600">
              Comprehensive solutions powered by cutting-edge AI technology
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {capabilities.map((item, index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition-all group">
                <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                  <item.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{item.title}</h3>
                <p className="text-gray-600 mb-6">{item.desc}</p>
                <ul className="space-y-2">
                  {item.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-500">
                      <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Key Benefits
            </h2>
            <p className="text-gray-600">
              Measurable improvements for your business
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Industry Solutions */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Industry Solutions
            </h2>
            <p className="text-gray-600">
              Tailored AI solutions for your sector
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{industry.title}</h3>
                <ul className="space-y-2">
                  {industry.uses.map((use, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2" />
                      {use}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Implementation Process */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Implementation Process
            </h2>
            <p className="text-gray-600">
              Simple and effective deployment
            </p>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-start relative">
            <div className="hidden md:block absolute top-1/2 left-0 w-full h-0.5 bg-blue-200 -translate-y-1/2" />
            
            {processSteps.map((step, index) => (
              <div key={index} className="relative flex-1 text-center px-4">
                <div className="bg-blue-600 w-10 h-10 rounded-full flex items-center justify-center text-white mx-auto mb-4 relative z-10">
                  {index + 1}
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-blue-600">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center text-white">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Start your AI journey today and stay ahead of the competition
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center justify-center">
                Schedule Demo <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                Contact Sales
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default GenerativeAI;