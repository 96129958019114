import React from 'react';
import { Send } from 'lucide-react';

const NewsletterSection = () => {
  return (
    <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Stay Ahead in AI Technology
          </h2>
          <p className="text-blue-100 mb-8">
            Get the latest AI insights, industry updates, and exclusive content delivered to your inbox
          </p>
          
          <form className="flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
            />
            <button 
              type="submit"
              className="bg-white text-blue-600 px-6 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center justify-center sm:justify-start"
            >
              Subscribe
              <Send className="ml-2 h-4 w-4" />
            </button>
          </form>
          
          <p className="text-blue-100 text-sm mt-4">
            Join 5,000+ professionals getting AI insights. Unsubscribe anytime.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection;
