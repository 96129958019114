import React from 'react';
import NavBar from '../../component/NavBar';
import Footer from '../../component/Footer';

const MobileAppDevelopment = () => {
    return (
        <div>
        <NavBar />
        <div class="container-fluid pt-5 bg-primary hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-8 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">Mobile App Development</h1>
                </div>
                <div class="col-lg-4 align-self-end text-center text-lg-end">
                    <img class="img-fluid" src="img/hero-img.png" alt="" style={{maxHeight: '300px'}}/>
                </div>
            </div>
        </div>
    </div>

        <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="about-img">
                        <img className="img-fluid" src="img/about-img.jpg" />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
                    <h1 className="mb-4">We Make Your Business Smarter with Artificial Intelligence</h1>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam
                        amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus
                        clita duo justo et tempor eirmod magna dolore erat amet</p>
                    <p className="mb-4">Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no
                        labore lorem sit. Sanctus clita duo justo et tempor.</p>
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <a className="btn btn-primary rounded-pill px-4 me-3" href="">Read More</a>
                        <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-primary btn-square me-3" href=""><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-outline-primary btn-square" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="container-fluid bg-primary feature pt-5">
        <div className="container pt-5">
            <div className="row g-5">
                <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                    <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Why Choose Us</div>
                    <h1 className="text-white mb-4">We're Best in AI Industry with 10 Years of Experience</h1>
                    <p className="text-light mb-4">Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed
                        stet no labore lorem sit. Sanctus clita duo justo et tempor</p>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Diam dolor diam ipsum et tempor sit</span>
                    </div>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Diam dolor diam ipsum et tempor sit</span>
                    </div>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Diam dolor diam ipsum et tempor sit</span>
                    </div>
                    <div className="row g-4 pt-3">
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: 'rgba(256, 256, 256, 0.1)'}}>
                                <i className="fa fa-home fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: 'rgba(256, 256, 256, 0.1)'}}>
                                <i className="fa fa-home fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">9999</h2>
                                    <p className="text-white mb-0">Project Complete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <img className="img-fluid" src="img/feature.png" alt="" />
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-fluid bg-light py-5">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Team</div>
                    <h1 className="mb-4">Meet Our Experienced Team Members</h1>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam
                        amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus
                        clita duo justo et tempor eirmod magna dolore erat amet</p>
                    <a className="btn btn-primary rounded-pill px-4" href="">Read More</a>
                </div>
                <div className="col-lg-7">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-1.jpg" alt="" />
                                        <h5 className="mb-0">Boris Johnson</h5>
                                        <small>Founder & CEO</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-2.jpg" alt="" />
                                        <h5 className="mb-0">Adam Crew</h5>
                                        <small>Executive Manager</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pt-md-4">
                            <div className="row g-4">
                                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-3.jpg" alt="" />
                                        <h5 className="mb-0">Kate Winslet</h5>
                                        <small>Co Founder</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                                    <div className="team-item bg-white text-center rounded p-4 pt-0">
                                        <img className="img-fluid rounded-circle p-4" src="img/team-4.jpg" alt="" />
                                        <h5 className="mb-0">Cody Gardner</h5>
                                        <small>Project Manager</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href=""><i
                                                    className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-fluid bg-primary newsletter py-5">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
                    <img className="img-fluid" src="img/newsletter.png" alt="" />
                </div>
                <div className="col-md-7 py-5 newsletter-text wow fadeIn" data-wow-delay="0.5s">
                    <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Newsletter</div>
                    <h1 className="text-white mb-4">Let's subscribe the newsletter</h1>
                    <div className="position-relative w-100 mt-3 mb-2">
                        <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                            placeholder="Enter Your Email" style={{height: '48px'}} />
                        <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                className="fa fa-paper-plane text-primary fs-4"></i></button>
                    </div>
                    <small className="text-white-50">Diam sed sed dolor stet amet eirmod</small>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
        </div>
    );
};

export default MobileAppDevelopment;