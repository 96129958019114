import React from 'react';
import { Database, LineChart, Search, Target, Brain, ArrowRight, ChevronRight, BarChart2, GitBranch, Workflow } from 'lucide-react';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';

const DataScience = () => {
  const services = [
    {
      icon: Database,
      title: "Data Analytics",
      desc: "Advanced analytics solutions to transform raw data into actionable insights",
      features: ["Predictive Analytics", "Statistical Analysis", "Data Visualization", "Real-time Analytics"]
    },
    {
      icon: Brain,
      title: "Machine Learning",
      desc: "Custom ML models for complex business challenges",
      features: ["Supervised Learning", "Unsupervised Learning", "Deep Learning", "Model Optimization"]
    },
    {
      icon: Search,
      title: "Data Mining",
      desc: "Extract valuable patterns and trends from large datasets",
      features: ["Pattern Recognition", "Trend Analysis", "Anomaly Detection", "Clustering"]
    },
    {
      icon: BarChart2,
      title: "Business Intelligence",
      desc: "Transform data into actionable business insights",
      features: ["KPI Monitoring", "Custom Dashboards", "Report Automation", "Data Storytelling"]
    },
    {
      icon: GitBranch,
      title: "Predictive Modeling",
      desc: "Forecast future trends and outcomes",
      features: ["Time Series Analysis", "Regression Models", "Classification Models", "Risk Assessment"]
    },
    {
      icon: Workflow,
      title: "Big Data Processing",
      desc: "Handle and analyze large-scale data efficiently",
      features: ["Data Pipeline Design", "ETL Processes", "Real-time Processing", "Data Warehousing"]
    }
  ];

  const industries = [
    {
      title: "Financial Services",
      applications: [
        "Risk Assessment",
        "Fraud Detection",
        "Market Analysis",
        "Portfolio Optimization"
      ]
    },
    {
      title: "Healthcare",
      applications: [
        "Patient Analytics",
        "Disease Prediction",
        "Treatment Optimization",
        "Resource Management"
      ]
    },
    {
      title: "Retail",
      applications: [
        "Customer Segmentation",
        "Demand Forecasting",
        "Inventory Optimization",
        "Price Optimization"
      ]
    },
    {
      title: "Manufacturing",
      applications: [
        "Quality Control",
        "Predictive Maintenance",
        "Supply Chain Optimization",
        "Process Optimization"
      ]
    }
  ];

  return (
    <div>
        <Navbar/>
   
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-700 to-blue-900 py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute -left-1/4 top-1/4 w-96 h-96 bg-blue-500/20 rounded-full blur-3xl animate-pulse" />
          <div className="absolute -right-1/4 bottom-1/4 w-96 h-96 bg-blue-600/20 rounded-full blur-3xl animate-pulse delay-300" />
        </div>

        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl text-white">
            <div className="inline-flex items-center space-x-2 bg-white/10 rounded-full px-4 py-2 mb-6">
              <LineChart className="h-5 w-5" />
              <span>Data Science Solutions</span>
            </div>
            <h1 className="text-5xl font-bold mb-6 leading-tight">
              Turn Data into Your Competitive Advantage
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Leverage advanced analytics and machine learning to unlock valuable insights 
              and drive data-informed decisions.
            </p>
            <div className="flex space-x-4">
              <button className="bg-white text-blue-700 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center">
                Start Your Journey <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white/30 text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                Explore Solutions
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Data Science Services
            </h2>
            <p className="text-gray-600">
              Comprehensive solutions to unlock the full potential of your data
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition-all group">
                <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                  <service.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.desc}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-500">
                      <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Industry Applications */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Industry Applications
            </h2>
            <p className="text-gray-600">
              Tailored data science solutions for your sector
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-all">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">{industry.title}</h3>
                <ul className="space-y-3">
                  {industry.applications.map((app, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <ChevronRight className="h-4 w-4 text-blue-600 mr-2" />
                      {app}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Data Science Process
            </h2>
            <p className="text-gray-600">
              A systematic approach to delivering results
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {['Data Collection', 'Analysis & Processing', 'Model Development', 'Implementation'].map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-gray-50 p-6 rounded-xl text-center">
                  <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center mx-auto mb-4">
                    {index + 1}
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{step}</h3>
                </div>
                {index < 3 && (
                  <div className="hidden lg:block absolute top-1/2 left-full w-8 h-0.5 bg-blue-200 transform -translate-y-1/2" />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-blue-600">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center text-white">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Unlock the Power of Your Data?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Let's transform your data into actionable insights
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center justify-center">
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                Talk to Expert
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default DataScience;