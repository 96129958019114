import React from 'react';
import { Pen, MessageSquare, Sparkles, Share2, Zap, Settings, ChevronRight, ArrowRight } from 'lucide-react';

const ContentCraftProduct = () => {
  const features = [
    {
      icon: Sparkles,
      title: "AI-Powered Content",
      desc: "Generate high-quality content using advanced AI models",
      features: ["SEO Optimization", "Brand Voice Matching", "Multiple Content Types"]
    },
    {
      icon: MessageSquare,
      title: "Multi-Platform",
      desc: "Create content for any platform in one place",
      features: ["Blog Posts", "Social Media", "Video Scripts", "Educational Material"]
    },
    {
      icon: Share2,
      title: "Easy Sharing",
      desc: "Share directly to multiple platforms",
      features: ["Direct Integration", "Schedule Posts", "Analytics Tracking"]
    },
    {
      icon: Settings,
      title: "Customization",
      desc: "Tailor content to your specific needs",
      features: ["Style Controls", "Length Adjustment", "Tone Settings"]
    }
  ];

  const useCases = [
    {
      title: "Content Creation",
      benefits: [
        "Blog posts in minutes",
        "Social media captions",
        "Video scripts",
        "Product descriptions"
      ]
    },
    {
      title: "Content Enhancement",
      benefits: [
        "SEO optimization",
        "Tone adjustment",
        "Content expansion",
        "Grammar checking"
      ]
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-600 to-indigo-800 py-28">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px]"/>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 bg-blue-700/40 rounded-full px-4 py-2 mb-6">
              <Pen className="h-5 w-5 text-white" />
              <span className="text-white">AI-Powered Content Creation</span>
            </div>
            <h1 className="text-5xl font-bold text-white mb-6">
              Content Craft
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Create engaging content for blogs, social media, and education with our 
              advanced AI content generation platform.
            </p>
            <div className="flex gap-4">
              <a href="/products/content-craft/try" className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors inline-flex items-center">
                Try Free <ArrowRight className="ml-2 h-5 w-5" />
              </a>
              <a href="/products/content-craft/demo" className="bg-blue-700 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-600 transition-colors">
                Watch Demo
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Key Features
            </h2>
            <p className="text-gray-600">
              Everything you need for professional content creation
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition-all">
                <feature.icon className="h-8 w-8 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.desc}</p>
                <ul className="space-y-3">
                  {feature.features.map((item, idx) => (
                    <li key={idx} className="flex items-center text-gray-500">
                      <ChevronRight className="h-4 w-4 text-blue-500 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Use Cases */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-16">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-8">
                Transform Your Content Creation
              </h2>
              <div className="space-y-8">
                {useCases.map((useCase, index) => (
                  <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                    <h3 className="text-xl font-semibold text-gray-900 mb-4">
                      {useCase.title}
                    </h3>
                    <ul className="grid grid-cols-2 gap-4">
                      {useCase.benefits.map((benefit, idx) => (
                        <li key={idx} className="flex items-center text-gray-600">
                          <Zap className="h-4 w-4 text-blue-500 mr-2" />
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Pricing Card */}
            <div className="relative">
              <div className="sticky top-8">
                <div className="bg-blue-600 rounded-2xl p-8 text-white">
                  <h3 className="text-2xl font-bold mb-2">Start Creating Today</h3>
                  <p className="text-blue-100 mb-6">
                    Free trial available. No credit card required.
                  </p>
                  <div className="bg-white/10 rounded-lg p-6 mb-8">
                    <div className="text-3xl font-bold mb-2">$29<span className="text-lg">/month</span></div>
                    <p className="text-blue-100">Everything you need to create amazing content</p>
                  </div>
                  <ul className="space-y-4 mb-8">
                    {[
                      "Unlimited AI content generation",
                      "All content types",
                      "Multi-platform sharing",
                      "Analytics and insights"
                    ].map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <ChevronRight className="h-5 w-5 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <a 
                    href="/products/content-craft/signup"
                    className="block w-full bg-white text-blue-600 text-center py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors"
                  >
                    Start Free Trial
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-12 text-center text-white">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Content?</h2>
            <p className="text-xl text-blue-100 mb-8">
              Join thousands of creators using Content Craft
            </p>
            <div className="flex justify-center gap-4">
              <a href="/products/content-craft/try" className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Try For Free
              </a>
              <a href="/products/content-craft/contact" className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-colors">
                Contact Sales
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentCraftProduct;
