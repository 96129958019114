import React from 'react';
import { FileText, Database, Search, Brain, FileSpreadsheet, Image, File, ArrowRight, ChevronRight, Zap } from 'lucide-react';

const DocSenseProduct = () => {
  const features = [
    {
      icon: FileText,
      title: "Multi-Format Support",
      desc: "Process multiple document types seamlessly",
      features: ["Word, PDF, Text", "CSV, Excel", "Image Documents", "OCR Support"]
    },
    {
      icon: Database,
      title: "Smart Storage",
      desc: "Organized and secure document storage",
      features: ["Auto-categorization", "Version Control", "Secure Encryption", "Cloud Backup"]
    },
    {
      icon: Search,
      title: "Intelligent Search",
      desc: "Find any information instantly",
      features: ["Full-text Search", "Semantic Search", "Quick Filters", "Advanced Queries"]
    },
    {
      icon: Brain,
      title: "AI Insights",
      desc: "Extract valuable insights from your documents",
      features: ["Pattern Recognition", "Trend Analysis", "Key Findings", "Custom Reports"]
    }
  ];

  const capabilities = [
    {
      title: "Document Processing",
      items: [
        "Automated data extraction",
        "Format conversion",
        "Data validation",
        "Batch processing"
      ]
    },
    {
      title: "Knowledge Management",
      items: [
        "Custom knowledge bases",
        "Semantic linking",
        "Topic clustering",
        "Insights generation"
      ]
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-700 to-indigo-900 py-28">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px]"/>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 bg-blue-600/40 rounded-full px-4 py-2 mb-6">
              <FileText className="h-5 w-5 text-white" />
              <span className="text-white">Document Intelligence Platform</span>
            </div>
            <h1 className="text-5xl font-bold text-white mb-6">
              DocSense
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Transform your documents into actionable insights with AI-powered 
              document processing and knowledge management.
            </p>
            <div className="flex gap-4">
              <a href="/products/docsense/demo" className="bg-white text-blue-700 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Request Demo
              </a>
              <a href="/products/docsense/learn" className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition-all">
                <feature.icon className="h-8 w-8 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.desc}</p>
                <ul className="space-y-3">
                  {feature.features.map((item, idx) => (
                    <li key={idx} className="flex items-center text-gray-500">
                      <ChevronRight className="h-4 w-4 text-blue-500 mr-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Capabilities */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-16">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-8">
                Advanced Capabilities
              </h2>
              <div className="space-y-8">
                {capabilities.map((capability, index) => (
                  <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                    <h3 className="text-xl font-semibold text-gray-900 mb-4">
                      {capability.title}
                    </h3>
                    <ul className="grid grid-cols-2 gap-4">
                      {capability.items.map((item, idx) => (
                        <li key={idx} className="flex items-center text-gray-600">
                          <Zap className="h-4 w-4 text-blue-500 mr-2" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Pricing Card */}
            <div className="relative">
              <div className="sticky top-8">
                <div className="bg-gradient-to-br from-blue-700 to-indigo-900 rounded-2xl p-8 text-white">
                  <h3 className="text-2xl font-bold mb-2">Enterprise Solution</h3>
                  <p className="text-blue-100 mb-8">
                    Custom pricing based on your organization's needs
                  </p>
                  <ul className="space-y-4 mb-8">
                    {[
                      "Unlimited document processing",
                      "Custom knowledge base",
                      "Advanced AI insights",
                      "Enterprise security",
                      "Dedicated support"
                    ].map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <ChevronRight className="h-5 w-5 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <a 
                    href="/products/docsense/contact"
                    className="block w-full bg-white text-blue-700 text-center py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors"
                  >
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="bg-gradient-to-r from-blue-700 to-indigo-900 rounded-2xl p-12 text-center text-white">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Document Management?</h2>
            <p className="text-xl text-blue-100 mb-8">
              Start turning your documents into insights today
            </p>
            <div className="flex justify-center gap-4">
              <a href="/products/docsense/demo" className="bg-white text-blue-700 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Schedule Demo
              </a>
              <a href="/products/docsense/contact" className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-colors">
                Talk to Expert
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DocSenseProduct;
