import React from 'react';
import { Server, Cloud, GitBranch, Settings, Shield, Terminal, Activity, RefreshCw, Code, Cpu, Network, Lock } from 'lucide-react';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';

const DevOpsEngineerring = () => {
  const services = [
    {
      icon: Cloud,
      title: "Cloud Infrastructure",
      desc: "Scalable cloud solutions and infrastructure management",
      features: ["AWS/Azure/GCP", "Infrastructure as Code", "Cloud Security", "Cost Optimization"]
    },
    {
      icon: GitBranch,
      title: "CI/CD Pipeline",
      desc: "Automated deployment and continuous integration",
      features: ["Automated Testing", "Continuous Deployment", "Version Control", "Build Automation"]
    },
    {
      icon: Terminal,
      title: "Automation",
      desc: "Process automation and workflow optimization",
      features: ["Task Automation", "Script Development", "Process Optimization", "Workflow Management"]
    },
    {
      icon: Shield,
      title: "Security",
      desc: "DevSecOps implementation and security automation",
      features: ["Security Scanning", "Compliance Checks", "Vulnerability Testing", "Access Control"]
    },
    {
      icon: Activity,
      title: "Monitoring",
      desc: "Real-time monitoring and performance optimization",
      features: ["Performance Metrics", "Log Analysis", "Alert Management", "System Health"]
    },
    {
      icon: Settings,
      title: "Configuration Management",
      desc: "Infrastructure and application configuration",
      features: ["Config Automation", "Environment Management", "Version Control", "Deployment Config"]
    },
    {
      icon: Network,
      title: "Container Orchestration",
      desc: "Docker and Kubernetes implementation",
      features: ["Container Management", "Service Scaling", "Load Balancing", "Service Mesh"]
    },
    {
      icon: RefreshCw,
      title: "Microservices",
      desc: "Microservice architecture implementation",
      features: ["Service Design", "API Management", "Service Discovery", "Fault Tolerance"]
    },
    {
      icon: Lock,
      title: "Compliance & Governance",
      desc: "Regulatory compliance and policy enforcement",
      features: ["Policy Management", "Audit Trails", "Compliance Checks", "Risk Management"]
    },
    {
      icon: Cpu,
      title: "Infrastructure Optimization",
      desc: "Performance tuning and resource optimization",
      features: ["Resource Planning", "Cost Management", "Performance Tuning", "Capacity Planning"]
    }
  ];

  return (
    <div>
        <Navbar/>
    
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-800 to-indigo-900 py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute -left-1/4 top-1/4 w-96 h-96 bg-blue-500/20 rounded-full blur-3xl animate-pulse" />
          <div className="absolute -right-1/4 bottom-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-pulse delay-300" />
        </div>

        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl text-white">
            <div className="inline-flex items-center space-x-2 bg-white/10 rounded-full px-4 py-2 mb-6">
              <Server className="h-5 w-5" />
              <span>DevOps Solutions</span>
            </div>
            <h1 className="text-5xl font-bold mb-6 leading-tight">
              Accelerate Your Development Pipeline
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Streamline your development workflow with automated processes, 
              enhanced collaboration, and robust infrastructure.
            </p>
            <div className="flex space-x-4">
              <button className="bg-white text-blue-800 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center">
                Get Started <Code className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white/30 text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Comprehensive DevOps Services
            </h2>
            <p className="text-gray-600">
              End-to-end solutions for modern development workflows
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition-all group">
                <div className="bg-blue-100 w-14 h-14 rounded-lg flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                  <service.icon className="h-7 w-7 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.desc}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-500">
                      <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Timeline */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our DevOps Implementation Process
            </h2>
            <p className="text-gray-600">
              Systematic approach to DevOps transformation
            </p>
          </div>

          <div className="max-w-4xl mx-auto">
            {[
              "Assessment & Planning",
              "Infrastructure Setup",
              "Pipeline Implementation",
              "Automation & Integration",
              "Monitoring & Optimization"
            ].map((step, index) => (
              <div key={index} className="relative flex items-center mb-8">
                <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white font-bold z-10">
                  {index + 1}
                </div>
                <div className="flex-1 ml-6">
                  <div className="bg-white p-6 rounded-xl shadow-sm">
                    <h3 className="text-xl font-semibold text-gray-900">{step}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Faster Deployment",
                desc: "Reduce deployment time by up to 90%"
              },
              {
                title: "Enhanced Quality",
                desc: "Minimize errors and improve code quality"
              },
              {
                title: "Cost Efficiency",
                desc: "Optimize resource utilization and reduce costs"
              }
            ].map((benefit, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-blue-600 mb-4">{benefit.title}</div>
                <p className="text-gray-600">{benefit.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-blue-800 py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center text-white">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Transform Your Development Workflow?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Let's build a more efficient, automated, and scalable development process
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="bg-white text-blue-800 px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all">
                Schedule Consultation
              </button>
              <button className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-all">
                View Documentation
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default DevOpsEngineerring;



