import React from 'react';
import { FileText, Pen, ChevronRight, Brain, Database, Sparkles, MessageSquare } from 'lucide-react';
import Navbar from '../component/NavBar';

const ProductsOverview = () => {
  const products = [
    {
      id: "content-craft",
      icon: Pen,
      title: "Content Craft",
      description: "AI-powered content generation platform for creators and businesses",
      features: [
        "Multi-platform content generation",
        "SEO optimization",
        "Brand voice customization",
        "Direct platform sharing"
      ],
      primaryColor: "from-blue-600 to-purple-600",
      href: "/products/content-craft",
      forText: "Perfect for content creators, marketers, and educators",
      keyPoints: [
        { icon: Sparkles, text: "AI-powered writing" },
        { icon: MessageSquare, text: "Multi-platform support" },
        { icon: Brain, text: "Smart templates" }
      ]
    },
    {
      id: "doc-sense",
      icon: FileText,
      title: "DocSense",
      description: "Intelligent document processing and knowledge management system",
      features: [
        "Multi-format document processing",
        "Smart knowledge base",
        "AI-powered insights",
        "Advanced search capabilities"
      ],
      primaryColor: "from-blue-700 to-indigo-600",
      href: "/products/doc-sense",
      forText: "Perfect for enterprises and organizations handling large documents",
      keyPoints: [
        { icon: Database, text: "Smart storage" },
        { icon: Brain, text: "AI insights" },
        { icon: FileText, text: "Multi-format support" }
      ]
    }
  ];

  return (
    <div>
        <Navbar/>
    
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-800 to-indigo-900 py-28">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px]" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl text-center mx-auto">
            <h1 className="text-5xl font-bold text-white mb-6">
              Our Products
            </h1>
            <p className="text-xl text-blue-100">
              Cutting-edge AI solutions designed to transform your business
            </p>
          </div>
        </div>
      </section>

      {/* Products Grid */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="space-y-16">
            {products.map((product, index) => (
              <div 
                key={product.id}
                className={`bg-white rounded-2xl overflow-hidden shadow-lg ${
                  index % 2 === 1 ? 'lg:flex-row-reverse' : ''
                } lg:flex`}
              >
                {/* Product Preview */}
                <div className="lg:w-1/2">
                  <div className={`bg-gradient-to-br ${product.primaryColor} p-12 h-full flex flex-col justify-center`}>
                    <product.icon className="h-16 w-16 text-white mb-6" />
                    <h2 className="text-3xl font-bold text-white mb-4">
                      {product.title}
                    </h2>
                    <p className="text-lg text-white/90 mb-8">
                      {product.description}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      {product.keyPoints.map((point, idx) => (
                        <div key={idx} className="flex items-center space-x-2 text-white">
                          <point.icon className="h-5 w-5" />
                          <span>{point.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Product Details */}
                <div className="lg:w-1/2 p-12">
                  <h3 className="text-gray-500 mb-6">
                    {product.forText}
                  </h3>
                  <div className="space-y-4 mb-8">
                    {product.features.map((feature, idx) => (
                      <div key={idx} className="flex items-center text-gray-700">
                        <ChevronRight className="h-5 w-5 text-blue-600 mr-2" />
                        {feature}
                      </div>
                    ))}
                  </div>
                  <div className="space-y-4">
                    <a 
                      href={product.href}
                      className="block w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white text-center py-3 rounded-lg font-medium hover:from-blue-700 hover:to-blue-800 transition-all"
                    >
                      Learn More
                    </a>
                    <a 
                      href={`${product.href}/demo`}
                      className="block w-full bg-gray-100 text-gray-700 text-center py-3 rounded-lg font-medium hover:bg-gray-200 transition-all"
                    >
                      Request Demo
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-12 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Let's discuss how our AI solutions can help you achieve your goals
            </p>
            <div className="flex justify-center gap-4">
              <a 
                href="/contact"
                className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors"
              >
                Contact Sales
              </a>
              <a 
                href="/demo"
                className="border border-white text-white px-8 py-3 rounded-lg font-medium hover:bg-white/10 transition-colors"
              >
                Schedule Demo
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default ProductsOverview;