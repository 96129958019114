import React from 'react';
import { Cpu, Code, Braces, Database, Layers, Settings, ArrowRight, Bot, LineChart, Shield } from 'lucide-react';
import Footer from '../../component/Footer';
import Navbar from '../../component/NavBar';


const AIIntegratedApplicationDevelopment = () => {
  const services = [
    {
      icon: Bot,
      title: "Intelligent Applications",
      desc: "Custom AI-powered applications tailored to your business needs",
      features: ["Smart Automation", "Predictive Features", "Real-time Processing", "Custom Algorithms"]
    },
    {
      icon: Cpu,
      title: "AI Integration",
      desc: "Seamless integration of AI capabilities into existing systems",
      features: ["API Integration", "System Compatibility", "Scalable Solutions", "Performance Monitoring"]
    },
    {
      icon: Database,
      title: "Data Processing",
      desc: "Advanced data processing and analysis solutions",
      features: ["Real-time Analytics", "Data Pipeline", "Custom Models", "Insights Generation"]
    },
    {
      icon: Layers,
      title: "Scalable Architecture",
      desc: "Build robust and scalable AI applications",
      features: ["Cloud-native", "Microservices", "Load Balancing", "Auto-scaling"]
    },
    {
      icon: Shield,
      title: "Security & Compliance",
      desc: "Enterprise-grade security for AI applications",
      features: ["Data Protection", "Access Control", "Audit Trails", "Compliance Standards"]
    }
  ];

  const industries = [
    {
      title: "Healthcare",
      applications: ["Patient Analytics", "Diagnostic Tools", "Treatment Planning"]
    },
    {
      title: "Finance",
      applications: ["Risk Assessment", "Fraud Detection", "Trading Systems"]
    },
    {
      title: "Retail",
      applications: ["Customer Analytics", "Inventory Management", "Price Optimization"]
    },
    {
      title: "Manufacturing",
      applications: ["Quality Control", "Predictive Maintenance", "Process Optimization"]
    }
  ];

  return (
    <div>
        <Navbar/>
    
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-800 to-purple-800 py-28">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px]"/>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 bg-blue-700/40 rounded-full px-4 py-2 mb-6">
              <Cpu className="h-5 w-5 text-white" />
              <span className="text-white">AI-Powered Applications</span>
            </div>
            <h1 className="text-5xl font-bold text-white mb-6">
              Build Intelligent Applications for Your Business
            </h1>
            <p className="text-xl text-blue-100 mb-8">
              Transform your business with custom AI-powered applications that deliver real value and drive innovation.
            </p>
            <div className="flex gap-4">
              <a href="/contact" className="bg-white text-blue-800 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Start Building
              </a>
              <a href="/portfolio" className="bg-blue-700 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-600 transition-colors">
                View Portfolio
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Development Services
            </h2>
            <p className="text-gray-600">
              End-to-end AI application development and integration
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transition-all">
                <service.icon className="h-8 w-8 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.desc}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-500">
                      <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"/>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Industry Solutions */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-8">
                Industry-Specific Solutions
              </h2>
              <div className="grid gap-6">
                {industries.map((industry, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-xl">
                    <h3 className="text-xl font-semibold text-gray-900 mb-4">{industry.title}</h3>
                    <ul className="grid gap-3">
                      {industry.applications.map((app, idx) => (
                        <li key={idx} className="flex items-center text-gray-600">
                          <ArrowRight className="h-4 w-4 text-blue-600 mr-2" />
                          {app}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative">
              <div className="sticky top-8">
                <div className="bg-gradient-to-br from-blue-800 to-purple-800 rounded-2xl p-8 text-white">
                  <h3 className="text-2xl font-bold mb-6">Ready to Build Your AI Application?</h3>
                  <p className="text-gray-200 mb-8">
                    Let's discuss how we can help transform your business with custom AI solutions.
                  </p>
                  <a 
                    href="/contact"
                    className="block w-full bg-white text-blue-800 text-center py-3 rounded-lg hover:bg-blue-50 transition-colors"
                  >
                    Schedule Consultation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default AIIntegratedApplicationDevelopment;

