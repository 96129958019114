import React from 'react';
import NavBar from '../component/NavBar';
import Footer from '../component/Footer';
import Hero from '../component/Hero';
import FeaturesSection from '../component/features-section';
import ProcessSection from '../component/process-section';
import CaseStudiesSection from '../component/case-studies-section';
import TestimonialsSection from '../component/testimonials-section';
import TeamSection from '../component/team-section';
import IndustryServices from '../component/industry-services';
import NewsletterSection from '../component/newsletter-section';

const Home = () => {
   
    return (
        <div>
        <NavBar/>
        <Hero/>
        <FeaturesSection/>
        <IndustryServices/>
        <ProcessSection/>
        <CaseStudiesSection/>
        <TestimonialsSection/>
        <TeamSection/>
        <NewsletterSection/>
        <Footer/>
    </div>
    );
};

export default Home;