import React from 'react';
import { Search, LineChart, Code, Check } from 'lucide-react';

const ProcessSection = () => {
  const steps = [
    {
      icon: Search,
      title: "Discovery & Analysis",
      description: "We dive deep into your business challenges to craft the perfect AI strategy.",
      details: ["Requirements gathering", "Data assessment", "Solution architecture"]
    },
    {
      icon: LineChart,
      title: "Strategy & Design",
      description: "Our experts design cutting-edge solutions tailored to your needs.",
      details: ["Technical planning", "ML model selection", "Implementation roadmap"]
    },
    {
      icon: Code,
      title: "Development & Training",
      description: "Building and training state-of-the-art AI models for your success.",
      details: ["Model development", "Integration setup", "Performance testing"]
    },
    {
      icon: Check,
      title: "Deployment & Growth",
      description: "Launching your solution with continuous optimization and support.",
      details: ["Production deployment", "Performance monitoring", "Continuous improvement"]
    }
  ];

  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white py-24">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 top-1/4 w-96 h-96 bg-blue-100 rounded-full blur-3xl opacity-30" />
        <div className="absolute -right-1/4 bottom-1/4 w-96 h-96 bg-blue-100 rounded-full blur-3xl opacity-30" />
      </div>

      <div className="relative container mx-auto px-4">
        <div className="text-center mb-20">
          <h2 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent mb-6">
            Our Proven Process
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            A systematic approach to transforming your business with AI
          </p>
        </div>

        <div className="grid lg:grid-cols-4 gap-8 relative">
          {/* Connection Lines */}
          <div className="hidden lg:block absolute top-24 left-0 w-full h-0.5 bg-gradient-to-r from-blue-200 via-blue-400 to-blue-200" />

          {steps.map((step, index) => (
            <div key={index} className="relative group">
              <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 h-full transform hover:-translate-y-2">
                {/* Step Number */}
                <div className="absolute -top-4 left-8 bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                  {index + 1}
                </div>
                
                {/* Icon */}
                <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl w-16 h-16 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  <step.icon className="h-8 w-8 text-blue-600" />
                </div>

                {/* Content */}
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{step.title}</h3>
                <p className="text-gray-600 mb-6 leading-relaxed">{step.description}</p>
                
                {/* Details */}
                <ul className="space-y-3">
                  {step.details.map((detail, idx) => (
                    <li key={idx} className="flex items-center text-gray-500">
                      <div className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2" />
                      <span className="group-hover:text-blue-600 transition-colors">{detail}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="mt-20 text-center">
          <button className="bg-gradient-to-r from-blue-600 to-blue-700 text-white px-8 py-4 rounded-xl font-medium text-lg hover:shadow-lg transition-shadow duration-300">
            Start Your AI Journey Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
