import React from 'react';
import { Brain, Database, Code, Server, Cpu, LineChart, Cloud, Lock } from 'lucide-react';

const FeaturesSection = () => {
  const features = [
    {
      icon: Brain,
      title: "Advanced AI Models",
      description: "Custom GPT and transformer models for natural language processing, image generation, and predictive analytics."
    },
    {
      icon: Database,
      title: "Data Analytics",
      description: "Transform raw data into actionable insights using advanced analytics and visualization techniques."
    },
    {
      icon: Cpu,
      title: "Machine Learning",
      description: "Implement cutting-edge ML algorithms for classification, prediction, and pattern recognition."
    },
    {
      icon: Code,
      title: "Custom Development",
      description: "Build tailored AI-powered applications that solve your unique business challenges."
    },
    {
      icon: Cloud,
      title: "Cloud Integration",
      description: "Seamless deployment and scaling of AI solutions across major cloud platforms."
    },
    {
      icon: Server,
      title: "DevOps Excellence",
      description: "Streamline development with automated testing, deployment, and monitoring."
    },
    {
      icon: LineChart,
      title: "Real-time Analytics",
      description: "Monitor and analyze data streams in real-time for immediate insights."
    },
    {
      icon: Lock,
      title: "Security First",
      description: "Enterprise-grade security with data encryption and compliance adherence."
    }
  ];

  return (
    <div className="bg-gray-50 py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Comprehensive AI Solutions
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            From data analysis to custom AI applications, we provide end-to-end solutions 
            tailored to your business needs.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-20 text-center">
          <div className="inline-flex items-center gap-8 bg-white p-8 rounded-xl shadow-lg">
            <div className="text-left">
              <h3 className="text-2xl font-bold text-gray-900 mb-2">
                Ready to Transform Your Business?
              </h3>
              <p className="text-gray-600">
                Schedule a consultation with our AI experts
              </p>
            </div>
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg font-medium">
              Book a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
