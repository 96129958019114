import React from 'react';
import { Brain, Target, Users, ArrowUpRight, ChevronRight, Rocket } from 'lucide-react';
import NavBar from '../component/NavBar';
import Footer from '../component/Footer';

const AboutUs = () => {
  const visionPoints = [
    { title: "AI Innovation", desc: "Building next-generation AI solutions" },
    { title: "Client Success", desc: "Dedicated to transforming businesses" },
    { title: "Tech Excellence", desc: "Cutting-edge development standards" },
    { title: "Future Ready", desc: "Preparing enterprises for AI evolution" }
  ];

  const teamStrengths = [
    {
      title: "Technical Excellence",
      desc: "Our team combines expertise in AI, ML, and enterprise solutions",
      icon: Brain
    },
    {
      title: "Innovation Focus",
      desc: "Committed to developing revolutionary AI solutions",
      icon: Target
    },
    {
      title: "Client-Centric",
      desc: "Building lasting partnerships through dedicated support",
      icon: Users
    }
  ];

  return (
    <div>
      <NavBar />

    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-600 via-blue-700 to-blue-900 text-white pt-32 pb-20 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-full h-full bg-[linear-gradient(30deg,#1e3a8a_12%,transparent_30%)]" />
          <div className="absolute -left-1/4 top-1/4 w-96 h-96 bg-blue-400/20 rounded-full blur-3xl animate-pulse" />
          <div className="absolute -right-1/4 bottom-1/4 w-96 h-96 bg-blue-500/20 rounded-full blur-3xl animate-pulse delay-700" />
        </div>
        
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <div className="inline-block bg-white/10 backdrop-blur-md px-4 py-2 rounded-full text-sm font-medium mb-6">
              Founded in January 2025
            </div>
            <h1 className="text-5xl font-bold mb-6 leading-tight">
              Pioneering the Future of AI Technology
            </h1>
            <p className="text-xl text-blue-100 mb-8 leading-relaxed">
              As a forward-thinking AI startup, we're on a mission to revolutionize how businesses 
              operate through innovative artificial intelligence solutions.
            </p>
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <div>
              <div className="flex items-center space-x-2 text-blue-600 mb-4">
                <Rocket className="h-5 w-5" />
                <span className="font-medium">Our Vision</span>
              </div>
              <h2 className="text-4xl font-bold text-gray-900 mb-6 leading-tight">
                Building Tomorrow's AI Solutions Today
              </h2>
              <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                At AI.Tech, we envision a future where artificial intelligence enhances every aspect 
                of business operations. As a young and dynamic team, we bring fresh perspectives 
                and innovative approaches to enterprise AI solutions.
              </p>
              <div className="grid sm:grid-cols-2 gap-6">
                {visionPoints.map((point, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-xl">
                    <h3 className="font-semibold text-gray-900 mb-2">{point.title}</h3>
                    <p className="text-gray-600">{point.desc}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 rounded-2xl transform rotate-3"></div>
              <img 
                src="/img/team-collaboration.svg" 
                alt="Team collaboration"
                className="relative rounded-2xl shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Team Strengths */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
              Why Choose Us
            </h2>
            <p className="text-lg text-gray-600">
              While we're new to the market, our team brings together decades of combined 
              experience in AI and enterprise solutions.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {teamStrengths.map((strength, index) => (
              <div key={index} className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
                <strength.icon className="h-12 w-12 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{strength.title}</h3>
                <p className="text-gray-600 mb-6">{strength.desc}</p>
                <a href="#" className="text-blue-600 flex items-center group">
                  Learn more 
                  <ArrowUpRight className="ml-2 h-4 w-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-blue-600 py-20">
        <div className="container mx-auto px-4">
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-12 text-center max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold text-white mb-6">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Join us at the forefront of the AI revolution. Let's build the future together.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="bg-white text-blue-600 px-8 py-4 rounded-xl text-lg font-medium hover:shadow-lg transition-all flex items-center justify-center">
                Schedule a Demo
                <ChevronRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white/50 text-white px-8 py-4 rounded-xl text-lg font-medium hover:bg-white/10 transition-all">
                View Our Solutions
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default AboutUs;