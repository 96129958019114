import React from 'react';
import { Brain, Database, Code, Users, BarChart, Lock } from 'lucide-react';

const TeamSection = () => {
  const stats = [
    { number: "50+", label: "AI Experts" },
    { number: "15+", label: "Years Combined Experience" },
    { number: "100+", label: "Enterprise Clients" },
    { number: "24/7", label: "Support" }
  ];

  const values = [
    { icon: Brain, title: "Innovation", desc: "Pushing boundaries in AI" },
    { icon: Users, title: "Collaboration", desc: "Partnership-driven approach" },
    { icon: BarChart, title: "Excellence", desc: "Delivering premium solutions" },
    { icon: Lock, title: "Security", desc: "Enterprise-grade protection" }
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-6">
            Pioneering AI Solutions for Tomorrow
          </h2>
          <p className="text-gray-600 leading-relaxed">
            We're a team of AI experts, data scientists, and engineers passionate about 
            transforming businesses through cutting-edge artificial intelligence solutions. 
            Our mission is to make AI technology accessible and impactful for enterprises worldwide.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-16">
          {stats.map((stat, index) => (
            <div key={index} className="text-center bg-white p-6 rounded-xl shadow-sm">
              <div className="text-3xl font-bold text-blue-600 mb-1">{stat.number}</div>
              <div className="text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>

        <div className="grid md:grid-cols-4 gap-6">
          {values.map((value, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm text-center group hover:shadow-md transition-all">
              <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center mx-auto mb-4 group-hover:scale-110 transition-transform">
                <value.icon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="font-semibold text-gray-900 mb-2">{value.title}</h3>
              <p className="text-gray-600 text-sm">{value.desc}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <button className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all">
            Join Our Team
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
