import React, { useState } from 'react';
import { ArrowRight, Mail, MapPin, Phone, Twitter, Facebook, Linkedin, ChevronUp } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [emailFeedback, setEmailFeedback] = useState('');

  const handleEmailSubmit = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailFeedback('Please enter a valid email address.');
    } else {
      setEmailFeedback('Thank you for subscribing!');
      setEmail('');
    }
  };

  return (
    <footer className="bg-gradient-to-br from-blue-900 to-indigo-800">
      <div className="container mx-auto px-4 pt-12 md:pt-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 pb-8 md:pb-12">
          {/* Company Info */}
          <div className="w-full">
            <div className="bg-white/10 backdrop-blur-md rounded-2xl p-6 md:p-8">
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Alpha<span className="text-blue-400">AI</span>Service
              </h2>
              <p className="text-white text-base md:text-lg mb-6">
                Leveraging cutting-edge AI technology to transform businesses and drive innovation.
              </p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full bg-white/20 px-4 py-3 rounded-lg text-white placeholder-gray-300 mb-3 focus:outline-none focus:ring-2 focus:ring-blue-400 text-base"
              />
              <button
                onClick={handleEmailSubmit}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg flex items-center justify-center text-base md:text-lg transition"
              >
                Get Updates <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              {emailFeedback && (
                <p className={`mt-2 text-sm ${emailFeedback.startsWith('Thank') ? 'text-green-400' : 'text-red-400'}`}>
                  {emailFeedback}
                </p>
              )}
            </div>
          </div>

          {/* Services */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-6 md:p-8">
            <h3 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Our Services</h3>
            <ul className="space-y-3 md:space-y-4">
              {['Generative AI', 'Data Science', 'DevOps', 'Custom AI Apps'].map((service, i) => (
                <li key={i}>
                  <a
                    href="#"
                    className="text-white text-base md:text-lg hover:text-blue-400 block transition"
                  >
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Links */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-6 md:p-8">
            <h3 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Quick Links</h3>
            <ul className="space-y-3 md:space-y-4">
              {['About Us', 'Case Studies', 'Contact', 'Careers'].map((link, i) => (
                <li key={i}>
                  <a
                    href="#"
                    className="text-white text-base md:text-lg hover:text-blue-400 block transition"
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-6 md:p-8">
            <h3 className="text-xl md:text-2xl font-bold text-white mb-4 md:mb-6">Contact Us</h3>
            <ul className="space-y-3 md:space-y-4">
              <li className="flex items-center">
                <Mail className="h-5 w-5 md:h-6 md:w-6 text-blue-400 mr-3 flex-shrink-0" />
                <span className="text-white text-base md:text-lg break-all">info@alphaaiservice.com</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white/20 py-6 md:py-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-white text-base md:text-lg text-center md:text-left">
              © 2024 Alpha AI Service. All rights reserved.
            </p>
            <div className="flex space-x-6">
              {[Twitter, Facebook, Linkedin].map((Icon, i) => (
                <a
                  key={i}
                  href="#"
                  className="text-white hover:text-blue-400 transition"
                  aria-label={`Follow us on ${Icon.name}`}
                >
                  <Icon className="h-5 w-5 md:h-6 md:w-6" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white p-2 md:p-3 rounded-full shadow-lg"
        aria-label="Scroll to top"
      >
        <ChevronUp className="h-4 w-4 md:h-5 md:w-5" />
      </button>
    </footer>
  );
};

export default Footer;