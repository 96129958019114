import React from 'react';
import { Building2, Briefcase, ShoppingBag, Stethoscope, Building, Factory, Plane, Leaf } from 'lucide-react';

const IndustryServices = () => {
  const industries = [
    {
      icon: Building2,
      title: "Enterprise",
      services: ["Business Process Automation", "Enterprise AI Integration"]
    },
    {
      icon: Building,
      title: "Financial Services",
      services: ["Fraud Detection", "Risk Analysis", "Algorithmic Trading"]
    },
    {
      icon: Stethoscope,
      title: "Healthcare",
      services: ["Diagnostic AI", "Patient Data Analytics"]
    },
    {
      icon: ShoppingBag,
      title: "Retail",
      services: ["Customer Analytics", "Inventory Optimization"]
    },
    {
      icon: Factory,
      title: "Manufacturing",
      services: ["Predictive Maintenance", "Quality Control"]
    },
    {
      icon: Plane,
      title: "Transportation",
      services: ["Route Optimization", "Demand Forecasting"]
    },
    {
      icon: Briefcase,
      title: "Insurance",
      services: ["Claims Processing", "Risk Assessment"]
    },
    {
      icon: Leaf,
      title: "Energy",
      services: ["Grid Optimization", "Consumption Analytics"]
    }
  ];

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            AI Solutions by Industry
          </h2>
          <p className="text-gray-600 mt-4">
            Tailored AI solutions for your industry-specific challenges
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {industries.map((industry, index) => (
            <div key={index} className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow group">
              <div className="flex items-start space-x-4">
                <div className="bg-blue-50 p-3 rounded-lg group-hover:scale-110 transition-transform">
                  <industry.icon className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900 mb-2">{industry.title}</h3>
                  <ul className="space-y-2">
                    {industry.services.map((service, idx) => (
                      <li key={idx} className="text-sm text-gray-600 flex items-center">
                        <div className="w-1.5 h-1.5 bg-blue-600 rounded-full mr-2"></div>
                        {service}
                      </li>
                    ))}
                  </ul>
                  <button className="mt-4 text-blue-600 text-sm font-medium group-hover:text-blue-700">
                    Learn more →
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <button className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all">
            Explore All Solutions
          </button>
        </div>
      </div>
    </div>
  );
};

export default IndustryServices;